var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Grid, Box, IconButton, Icon } from "@mui/material";
import logo from "../../Assets/images/DDT_logo.svg";
import { Button, Modal, Tooltip, Typography } from "@inspera/component-library";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useStore from "../../Store/store";
import { APP_VERSION, GIT_VERSION } from "../../Utils/constants";
import KeyIcon from '@mui/icons-material/Key';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import DataViewList from "../Modal/DataView/DataViewList";
var Navbar = function (_a) {
    var title = _a.title, _b = _a.hideBtn, hideBtn = _b === void 0 ? false : _b, _c = _a.hideMpName, hideMpName = _c === void 0 ? true : _c, _d = _a.isSavedView, isSavedView = _d === void 0 ? false : _d, _e = _a.setIsSavedView, setIsSavedView = _e === void 0 ? function () { } : _e;
    var _f = useStore(), domain = _f.domain, marketplaceId = _f.marketplaceId, token = _f.token;
    var _g = useState(false), openDataViewTable = _g[0], setOpenDataViewTable = _g[1];
    var navigate = useNavigate();
    var mpName = domain != ""
        ? domain.split(".")[0] == "iad"
            ? marketplaceId
            : domain.split(".")[0]
        : marketplaceId;
    var dataViewSvgIcon = (_jsx(Icon, { children: _jsx("img", { alt: "data view", src: "../../Assets/images/dataView.svg" }) }));
    var onCopyHandler = function () {
        navigator.clipboard.writeText(token);
    };
    return (_jsxs(Grid, __assign({ container: true, boxShadow: "0 4px 4px -2px rgb(39 39 39 / 12%)", height: "79px", sx: {
            background: "white",
            alignItems: "center",
            justifyContent: "space-between",
        } }, { children: [_jsxs(Stack, __assign({ direction: "row", px: 2, sx: {
                    alignItems: "flex-start",
                    justifyContent: "center",
                    verticalAlign: "center",
                } }, { children: [_jsx(Box, { component: "img", sx: { height: "24px", weight: "24px", mr: "8px", cursor: "pointer" }, alt: "DDT Logo", "aria-hidden": "true", src: logo, onClick: function () {
                            window.location.reload();
                        } }), _jsxs(Stack, __assign({ py: "3px", spacing: 0 }, { children: [_jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsx(Typography, __assign({ variant: "h2", component: "span", m: 0, onClick: function () {
                                            window.location.reload();
                                        }, gutterBottom: true, sx: { flexGrow: 1, lineHeight: "19px", cursor: "pointer" } }, { children: title })), _jsx(Typography, __assign({ sx: { margin: " 0 0 0 5px", fontSize: "10px" } }, { children: GIT_VERSION !== '' ? "".concat(APP_VERSION, " - ").concat(GIT_VERSION) : APP_VERSION }))] })), _jsx(Typography, __assign({ sx: { visibility: hideMpName ? "hidden" : "visible" }, m: 0 }, { children: mpName }))] }))] })), _jsxs(Stack, __assign({ direction: "row", sx: {
                    alignItems: "center",
                } }, { children: [_jsx(Tooltip, __assign({ title: "Help" }, { children: _jsx(IconButton, __assign({ sx: {
                                mr: 3,
                                width: "28px",
                                height: "28px",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
                            }, target: "_blank", href: "https://inspera.atlassian.net/l/cp/ZPY53Usr" }, { children: _jsx(HelpOutlineOutlinedIcon, { sx: { fontSize: "25px" } }) })) })), _jsx(Tooltip, __assign({ title: "Copy token" }, { children: _jsx(IconButton, __assign({ sx: {
                                mr: 3,
                                width: "28px",
                                height: "28px",
                                boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
                            }, onClick: onCopyHandler }, { children: _jsx(KeyIcon, { sx: { fontSize: "25px" } }) })) })), hideBtn && !isSavedView && (_jsx(Button, __assign({ variant: "outlined", color: "primary", sx: { margin: "0 10px 0 0" }, onClick: function () { return setOpenDataViewTable(true); } }, { children: "Dataviews" }))), hideBtn && !isSavedView && (_jsx(Button, __assign({ tabIndex: 1, variant: "outlined", sx: {
                            whiteSpace: "nowrap",
                            width: "119px",
                            height: "44px",
                            mr: "17px",
                        }, onClick: function () { return setIsSavedView(true); }, color: "primary" }, { children: "Saved views" })))] })), _jsx(Modal, __assign({ open: openDataViewTable, onBackdropClick: function () { return setOpenDataViewTable(false); }, onClose: function () { return setOpenDataViewTable(false); }, width: "lg", fullWidth: false }, { children: _jsx(DataViewList, { handleClose: function () { return setOpenDataViewTable(false); } }) }))] })));
};
export default Navbar;
